const firebaseConfig = {
  projectId: "the-charlotte-fopoi",
  appId: "1:153998785302:web:b58c039a25d274997f2e37",
  databaseURL:
    "https://the-charlotte-fopoi-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "the-charlotte-fopoi.appspot.com",
  locationId: "europe-west",
  apiKey: "AIzaSyCoSL0qMt7FmfrmaLJiuGKl8mLWIPt7H4Q",
  authDomain: "the-charlotte-fopoi.firebaseapp.com",
  messagingSenderId: "153998785302",
  measurementId: "G-GMSWC7L112",
};

export default firebaseConfig;
